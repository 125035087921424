import React from "react"
import { Router } from "@reach/router"
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LoginLayout from "../components/layout/login-layout/login-layout"
import Login from "../components/login/login"
import Signup from "../components/signup/signup"
import "../styles/vendor/_toastify.scss"
import "../styles/vendor/_tooltip.scss"
import DetailedFile from "../components/media-output/detailed-file/detailed-file"
import RedirectedPage from "./redirected-page"
import("../styles/FHAA_theme.scss")

const App = () => {
  return (
    <LoginLayout>
      <Router basepath="/app">
          <Signup path="/signup"></Signup>
          <Login path="/login"></Login>
          <DetailedFile path="/detailed-file/:type/:id"></DetailedFile>
          {/* <RedirectedPage 
            path="/redirected-page"
          ></RedirectedPage> */}
      </Router>
    </LoginLayout>
  )
}

export default App
